import React from 'react'
import { useNavigate } from 'react-router-dom'
import reactlogo from "../gtup.svg";

/* 
Define Menu Items
*/

const Navbar = () => {

  const navigate=useNavigate();

  const menuItems=[
    {label:'ABOUTS', path:'/about'},
    {label:'BUSINESS SOLUTIONS', path:'/business-solutions'},
    {label:'SERVICES', path:'/services'},
    {label:'INDUSTRIES', path:'/industries'},
    {label:'TECHNOLOGIES', path:'/technologies'},
    {label:'PORTFOLIO', path:'/portfolio'},
    {label:'CONTACT', path:'/contact'},
  ];
  const handlenavigation=(path)=>{
    const banner=document.getElementById('banner');
    navigate(path);
    banner.style.display='none';
  }
  const showbanner=()=>{
    const banner=document.getElementById('banner');
    navigate('/');
    banner.style.display='block';
  }
  return (
    <div>
      <nav className='menunav'>
        <div className='logo' onClick={showbanner} 
          style={{cursor:'pointer'}}>
        <img src={reactlogo} alt='' width={150} height={150}  />
        </div>
      {/* 
      Render Menu Items
      */}
      {menuItems.map((item,index)=>(
        <li key={index} onClick={()=>handlenavigation(item.path)}>
          {item.label}
        </li>
      ))}
      </nav>
    </div>
  )
}

export default Navbar