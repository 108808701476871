import React from 'react'
import HeaderContent from './components/HeaderContent'
import Navbar from './components/Navbar'

import Contaner from './components/Contaner'
import Footer from './components/Footer'
import Prefooter from './Prefooter'
import Bannerarea from './components/Bannerarea'

const App = () => {
  return (
    <div>
    <HeaderContent />
      <Navbar />
      <Bannerarea />
      <Contaner />
      <Prefooter />
      <Footer />
    </div>
  )
}

export default App