import ListGroup from 'react-bootstrap/ListGroup';

function Prefootercenter() {
  return (
    <ListGroup as="ul">
      <ListGroup.Item as="li">
        Services
      </ListGroup.Item>
      <ListGroup.Item as="li">Web Application</ListGroup.Item>
      <ListGroup.Item as="li">
        Mobile Applications
      </ListGroup.Item>
      <ListGroup.Item as="li">Business Applications</ListGroup.Item>
      <ListGroup.Item as="li">UI and UX</ListGroup.Item>
    </ListGroup>
  );
}

export default Prefootercenter;