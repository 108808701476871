import ListGroup from 'react-bootstrap/ListGroup';
import logo from './gtup.svg';
function Prefooterleft() {
  return (
    <ListGroup as="ul">
      <ListGroup.Item as="li">
       <img src={logo} alt='' height={80}/>
      </ListGroup.Item>
      <ListGroup.Item as="li">
      
      <i class="fa-solid fa-location-dot"></i> Opp. Konark Theater, Dilsukhnagar, Hyderabad - 500060.
      </ListGroup.Item>

      <ListGroup.Item as="li" disabled style={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:'10px 60px'}}>
      <i class="fa-brands fa-facebook"></i> <i class="fa-brands fa-twitter"></i> <i class="fa-brands fa-instagram"></i> <i class="fa-brands fa-github"></i> <i class="fa-brands fa-linkedin"></i> 
      </ListGroup.Item>
     
    </ListGroup>
  );
}

export default Prefooterleft;