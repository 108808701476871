import React from 'react'

const HeaderContent = () => {
    const style={
        header:{
            'height':'100px',
            'background':'lightblue',
            'text-align':'right',
            'line-height':'100px'
        },

    }
    const headercontent={
        'companyname':'JEEVAN INIT',
        'logo':'',
        'email':'contact@globaltieup.com',
        'phone':'+91 9393898933'
    };

  return (
    <div>
        <header>
        
        <div className='login'>

        <i class="fa-solid fa-envelope"></i> {headercontent.email} |  <i class="fa-solid fa-phone"></i> {headercontent.phone}
        </div>
      
        </header>
    </div>
  )
}

export default HeaderContent