import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

function Portfoliocontent() {
  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={3}>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="service">BY SERVICE</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="solution">BY SOLUTION</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="technology">BY TECHNOLOGY</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="industry">BY INDUSTRY</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="region">BY REGION</Nav.Link>
            </Nav.Item>
            
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="service">
BY SERVICE
Software Development,
Web Development,
Mobile App Development,
Testing and QA,
Application Services,
UI/UX Design,
Infrastructure Services,
Digital Transformation,
Managed IT Services

            </Tab.Pane>
            <Tab.Pane eventKey="solution">
            BY SOLUTION
ERP Implementation,
CRM Implementation,
E-commerce Solution,
IoT Integration,
Big Data Analytics,
Blockchain Solution,
AI Integration,
VR/AR Solutions
Computer Vision

            </Tab.Pane>
            <Tab.Pane eventKey="technology">
            BY TECHNOLOGY
.NET,
Java,
Python,
PHP,
Golang,
C++,
Node.js,
JavaScript,
React Native

            </Tab.Pane>
            <Tab.Pane eventKey="industry">
            BY INDUSTRY
Finance,
Banking,
Insurance,
Lending,
Investment,
FinTech,
Payments,
Retail,
Healthcare

            </Tab.Pane>
            <Tab.Pane eventKey="region">BY REGION
North America,
Europe,
Asia-Pacific,
Middle East,
Africa,
South America,
Central America,
Caribbean Islands,
Oceania
</Tab.Pane>
            
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
}

export default Portfoliocontent;