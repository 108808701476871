import React from 'react'
import ContactForm from './ContactForm'

const Contact = () => {
  return (
    <div>
        <h1>Welcome to Contact info....</h1>
        <ContactForm />
    </div>
  )
}

export default Contact