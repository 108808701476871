import ListGroup from 'react-bootstrap/ListGroup';

function Prefooterright() {
  return (
    <ListGroup as="ul">
      <ListGroup.Item as="li">
       contact@globaltieup.com
      </ListGroup.Item>
      <ListGroup.Item as="li"> Contact: 91+ 9393898933</ListGroup.Item>
      <ListGroup.Item as="li">Privacy Policy</ListGroup.Item>
      <ListGroup.Item as="li">Terms of Use</ListGroup.Item>
      <ListGroup.Item as="li">Job Opportunities</ListGroup.Item>
    </ListGroup>
  );
}

export default Prefooterright;