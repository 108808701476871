import React from 'react'
import Portfoliocontent from './PortfolioContent'

const Portfolio = () => {
  return (
    <div>
        <Portfoliocontent />
    </div>
  )
}

export default Portfolio