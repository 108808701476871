import React from 'react'
import Aside from './Aside'
import Article from './Article'
import { Route, Routes } from 'react-router-dom'
import Api from './Api'
import About from './About'
import Service from './Service'
import Contact from './Contact'
import Career from './Career'
import Business_Solutions from './Business_Solutions'
import Industries from './Industries'
import Technologies from './Technologies'
import Portfolio from './Portfolio'


const Contaner = () => {

  return (
    <div className='bodycontainer'>

{/*       <aside>
        <Aside />
      </aside> */}
      <article>
        
       {/*  <Article /> */}
        <Routes>
          <Route path='/' element={<About />}/>
          <Route path='/about' element={<About />}/>
          <Route path='/business-solutions' element={<Business_Solutions />}/>
          <Route path='/services' element={<Service />}/>
          <Route path='/industries' element={<Industries />}/>
          <Route path='/technologies' element={<Technologies />}/>
          <Route path='/portfolio' element={<Portfolio />}/>
          <Route path='/contact' element={<Contact />}/>
        </Routes>
      </article>
    </div>
  )
}

export default Contaner